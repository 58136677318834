import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function confirmPassword(password?: string, confirmation?: string): ValidatorFn {
    return (form: AbstractControl): ValidationErrors | null => {
        const passwordControl: string = password ?? 'password';
        const confirmationControl: string = confirmation ?? 'password_confirmation';

        return form.get(passwordControl)?.value === form.get(confirmationControl)?.value
            ? null
            : {passwordsUnconfirmed: true};
    };
}

export function passwordStrength(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        let value: string = control.value || '';

        if (!value) {
            return null;
        }

        const hasUpperCase: boolean = /[A-Z]+/.test(value);
        const hasLowerCase: boolean = /[a-z]+/.test(value)
        const hasNumerical: boolean = /[0-9]+/.test(value)
        const hasSpecial: boolean = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value)
        const hasLength: boolean = value.length >= 8;
        const numOfValid: number = +hasUpperCase + +hasLowerCase + +hasNumerical + +hasSpecial;
        const errors: any = {};
        let isPasswordValid: boolean = false;

        if (numOfValid < 3 || !hasLength) {
            if (!hasUpperCase) {
                errors['uppercase'] = 'Your password must contain at least 1 uppercase case character';
            }

            if (!hasLowerCase) {
                errors['lowercase'] = 'Your password must contain at least 1 lowercase character.';
            }

            if (!hasNumerical) {
                errors['numerical'] = 'Your password must contain at least 1 numerical character.';
            }

            if (!hasSpecial) {
                errors['special'] = 'Your password must contain at least 1 special character.';
            }

            errors['length'] = 'Your password must contain at least 8 characters.';
        } else {
            isPasswordValid = true;
        }

        return isPasswordValid ? null : {passwordStrength: errors};
    };
}
